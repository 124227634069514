import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Typography } from 'tfc-components';

import products from 'assets/images/products.png';
import MainLayout from 'components/templates/MainLayout';
import { useAppSelector } from 'store/hooks';
import { formatDateDDMMYYYY } from 'utils/functions';

const EndPhase: React.FC = () => {
  const navigate = useNavigate();
  const othersData = useAppSelector((state) => state.system.system?.others);
  useEffect(() => {
    if (othersData?.eventEndedTime) return;
    if (!othersData?.timeRemaining) {
      navigate('/');
    }
  }, [navigate, othersData]);
  return (
    <MainLayout bottomNav>
      <div className="p-endPhase">
        <div className="p-endPhase_popup">
          <div className="p-endPhase_heading">
            <Typography.Heading type="h2">
              CHƯƠNG TRÌNH KHUYẾN MÃI Goguma TỪ NGÀY
              {' '}
              {formatDateDDMMYYYY(othersData?.from)}
              {' '}
              ĐẾN
              {' '}
              {formatDateDDMMYYYY(othersData?.to)}
              <br />
              ĐÃ KẾT THÚC
            </Typography.Heading>
          </div>
          <div className="p-endPhase_content">
            <Typography.Text>
              Thường xuyên theo dõi thông tin tại
              <br />
              Fanpage Nabati Việt Nam hoặc
              Website nabativn.com
              để biết thêm các chương trình khuyến mãi khác.
            </Typography.Text>
          </div>
        </div>
        <div className="p-endPhase_image">
          <Image imgSrc={products} alt="Nabati" ratio={221 / 144} />
        </div>
      </div>
    </MainLayout>
  );
};

export default EndPhase;
